import { createEvent, restore, sample, createEffect } from 'effector';
import { Tenant } from 'shared/types/tenant';
import { cookiesService } from 'shared/cookies';

const setTenantEv = createEvent<Tenant>();
const reloadPageEv = createEvent();
const setIsDisabledEv = createEvent<boolean>();

const $tenant = restore<Tenant>(setTenantEv, 'howly');
const $isDisabled = restore(setIsDisabledEv, false);

sample({
    clock: $tenant,
    source: {
        cookies: cookiesService.$cookies,
        tenant: $tenant,
    },
    fn: ({ tenant }) => ({
        name: 'tenant',
        value: tenant,
        attributes: {
            path: '/',
            maxAge: 864000000,
        },
    }),
    target: cookiesService.setEv,
});

sample({
    clock: reloadPageEv,
    target: createEffect(() => {
        window.location.reload();
    }),
});

export const tenantModel = {
    events: {
        setTenantEv,
        setIsDisabledEv,
        reloadPageEv,
    },
    stores: {
        $tenant,
        $isDisabled,
    },
};
