import { BadRequest } from 'grpc-web-error-details/dist/lib/error_details_pb';

const getWebGrpcErrorDetails = (
    err: any
) => BadRequest.deserializeBinary(err.details[0].value)
    .getFieldViolationsList()
    .map(arr => ({
        field: arr.getField(),
        description: arr.getDescription(),
    }))
    .filter(Boolean)
    .reduce<Record<string, string>>((acc, item) => {
        acc[item.field] = item.description;

        return acc;
    }, {});

export default getWebGrpcErrorDetails;
