import { compose } from 'shared/utils';

import withHelmet from './with-helmet';
import withMui5 from './with-mui5';
import withRouter from './with-router';
import withPreferences from './with-preferences';

/**
 * @hoc Initialize app logic
 */
export const withProviders = compose(withHelmet, withPreferences, withRouter, withMui5);

