import { useState } from 'react';

const useTabsNavigationHandlers = (initialSelectedTab: number) => {

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(initialSelectedTab);

    const handleTabSelect = (_, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return {
        selectedTabIndex,
        handleTabSelect,
    };
};

export { useTabsNavigationHandlers };
