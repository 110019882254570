import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';
import { GenericCardProps } from 'shared/types';
import NavCollapse from 'shared/layout/MainLayout/Sidebar/MenuList/NavCollapse';
import NavItem from 'shared/layout/MainLayout/Sidebar/MenuList/NavItem';
import { useUnit } from 'effector-react';
import { isHasPermission } from 'shared/utils/isHasPermission';
import { permissionConfig } from 'shared/permission-config';
import { $userRole } from 'shared/models/user-info';

export interface NavGroupProps {
    item: {
        id?: string;
        type?: string;
        icon?: GenericCardProps['iconPrimary'];
        children?: NavGroupProps['item'][];
        title?: ReactNode | string;
        caption?: ReactNode | string;
        color?: 'primary' | 'secondary' | 'default' | undefined;
    };
}

const NavGroup = ({ item }: NavGroupProps) => {
    const theme = useTheme();
    const userRole = useUnit($userRole);

    // menu list collapse & items
    const items = item.children
        ?.filter(item => {
            if (!item?.id || !permissionConfig[item?.id] || permissionConfig[item?.id ?? '']?.access?.length === 0) {
                return true;
            } else {
                return isHasPermission(permissionConfig[item?.id ?? '']?.access, userRole);
            }
        })
        ?.map(menu => {
            switch (menu.type) {
                case 'collapse':
                    return (
                        <NavCollapse
                            key={menu.id}
                            menu={menu}
                            level={1}
                        />
                    );
                case 'item':
                    return (
                        <NavItem
                            key={menu.id}
                            item={menu}
                            level={1}
                        />
                    );
                default:
                    return (
                        <Typography
                            key={menu.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return items && items?.length > 0
        ? (
            <>
                <List subheader={
                    item.title && (
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.menuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.title}
                            {item.caption && (
                                <Typography
                                    variant="caption"
                                    sx={{ ...theme.typography.subMenuCaption }}
                                    display="block"
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
                >
                    {items}
                </List>

                {/* group divider */}
                <Divider sx={{
                    mt: 0.25,
                    mb: 1.25,
                }}
                />
            </>
        )
        : null;
};

export default NavGroup;
