export enum Role {
    SEO_ADMIN = 'seo_admin',
    SEO_SENIOR_EDITOR = 'seo_senior_editor',
    SEO_EDITOR = 'seo_editor',
    LANDINGS_EDITOR = 'landings_editor',
    PAYMENTS_PRODUCTS_EDITOR = 'payments_products_editor',
    SUPER_ADMIN = 'super_admin',
    LEGAL_ENTITY_EDITOR = 'legal_entity_editor',
    CUSTOMER_MANAGER = 'customer_manager',
    PAYMENTS_MANAGER = 'payments_manager'
}

export interface AccessToken {
    exp: number;
    iat: number;
    id: string;
    role: Role[];
    email: string;
    tokenType: 'access';
}
