import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { DASHBOARD_PATH } from 'shared/config';
import Logo from 'shared/components/Logo';

const LogoSection = () => (
    <Link
        component={RouterLink}
        to={DASHBOARD_PATH}
    >
        <Logo width={40} />
    </Link>
);

export default LogoSection;
