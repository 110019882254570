import { FormattedMessage } from 'react-intl';
import { IconList } from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

const managers = {
    id: 'manager',
    title: <FormattedMessage id="Admin" />,
    type: 'group',
    children: [
        {
            id: Pages.managersList,
            title: 'Managers',
            type: 'item',
            icon: IconList,
            breadcrumbs: false,
            url: AppRoutes.ManagersList,
        },
    ],
};

export default managers;
