// Sidebar menu module

import { createEvent, restore } from 'effector';

const openDrawerEv = createEvent<boolean>('toggle sidebar drawer');

const activeItemEv = createEvent<string[]>('set sidebar active menu item');

/** Sidebar's active menu item */
const $selectedItem = restore(activeItemEv, ['dashboard']);
/** Sidebar's drawer open/close state  ./*/
const $drawerOpen = restore(openDrawerEv, false);

export const menuModel = { $selectedItem, $drawerOpen, openDrawerEv, activeItemEv };
