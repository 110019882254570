import { ConfigProps } from 'shared/types/config';
import { Tenant } from 'shared/types/tenant';

export const BASE_PATH = '';

export const APP_NAME = 'Backoffice';

export const SERVICE_HOST = '/web-grpc';

export const DASHBOARD_PATH = '/dashboard';

const config: ConfigProps = {
    fontFamily: '\'Roboto\', sans-serif',
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    container: false,
};

export const tenantList: Tenant[] = [
    'howly',
    'ask_crew',
    'expert_squad',
    'legal_answers',
    'legal_crew',
    'ask_crewanswer',
    'geekaskanswers',
    'howly_docs',
    'pdf_aid',
    'ask_crew_expert',
    'real_ask_experts',
    'ask_squad',
    'ask_answerly',
    'better_cv',
    'experts_online',
    'anyexperts',
    'car_expert_now',
];

export default config;
