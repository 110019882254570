import { Alert, Button, Fade, Grow, IconButton, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { KeyedObject } from 'shared/types';
import { useUnit } from 'effector-react';

import { snackbarModel } from './index';

// animation function
const TransitionSlideLeft = (props: SlideProps) => (
    <Slide
        {...props}
        direction="left"
    />
);

const TransitionSlideUp = (props: SlideProps) => (
    <Slide
        {...props}
        direction="up"
    />
);

const TransitionSlideRight = (props: SlideProps) => (
    <Slide
        {...props}
        direction="right"
    />
);

const TransitionSlideDown = (props: SlideProps) => (
    <Slide
        {...props}
        direction="down"
    />
);

const GrowTransition = (props: SlideProps) => <Grow {...props} />;

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade,
};

const Snackbar = () => {
    const snackbar = useUnit(snackbarModel.$snackbarState);
    const { actionButton, anchorOrigin, alert, close, message, open, transition, variant } = snackbar;

    const handleClose = (_, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        snackbarModel.closeSnackbarEv();
    };

    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={(
                        <>
                            <Button
                                color="secondary"
                                size="small"
                                onClick={handleClose}
                            >
                                UNDO
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    )}
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert
                        severity={alert.severity}
                        variant={alert.variant}
                        color={alert.color}
                        action={(
                            <>
                                {actionButton && (
                                    <Button
                                        size="small"
                                        onClick={handleClose}
                                        sx={{ color: 'background.paper' }}
                                    >
                                        UNDO
                                    </Button>
                                )}
                                {close && (
                                    <IconButton
                                        sx={{ color: 'background.paper' }}
                                        size="small"
                                        aria-label="close"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        )}
                        sx={{
                            ...(alert.variant === 'outlined' && {
                                bgcolor: 'background.paper',
                            }),
                        }}
                    >
                        <div dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                        />
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export { Snackbar };
