import { useAppConfig } from 'shared/hooks';

const Logo = ({ width }: { width?: number }) => {
    const { logo } = useAppConfig();

    return (
        <img
            src={logo}
            alt="Berry"
            width={width || '100'}
        />
    );
};

export default Logo;
