import { ReactNode, useMemo } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, Theme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { useLayoutConfig } from 'shared/hooks';
import Palette from 'app/providers/with-mui5/themes/palette';
import Typography from 'app/providers/with-mui5/themes/typography';
import componentStyleOverrides from 'app/providers/with-mui5/themes/compStyleOverride';
import customShadows from 'app/providers/with-mui5/themes/shadows';
import { CustomShadowProps } from 'shared/types/default-theme';
import { TypographyOptions } from '@mui/material/styles/createTypography';

interface Props {
    children: ReactNode;
}

const ThemeCustomization = ({ children }: Props) => {
    const { borderRadius, fontFamily, navType, outlinedFilled, presetColor } = useLayoutConfig();

    const theme: Theme = useMemo<Theme>(() => Palette(navType, presetColor), [navType, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography: TypographyOptions = useMemo<TypographyOptions>(
        () => Typography(theme, borderRadius, fontFamily),
        [theme, borderRadius, fontFamily]
    );

    const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => customShadows(navType, theme), [navType, theme]);

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: theme.palette,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px',
                    },
                },
            },
            typography: themeTypography,
            customShadows: themeCustomShadows,
        }),
        [theme, themeCustomShadows, themeTypography]
    );

    const themes: Theme = createTheme(themeOptions);

    themes.components = useMemo(
        () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
        [themes, borderRadius, outlinedFilled]
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeCustomization;
