import { FormattedMessage } from 'react-intl';
import {
    IconBorderAll,
    IconCompass,
    IconFilter,
    IconNews,
    IconUsers,
    IconMessageCircle2,
    IconMessages,
    IconCategory2,
} from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

const cms = {
    id: 'cms',
    title: <FormattedMessage id="CMS" />,
    type: 'group',
    children: [
        {
            id: Pages.articles,
            title: 'Articles',
            type: 'item',
            icon: IconNews,
            breadcrumbs: false,
            url: AppRoutes.ArticleList,
        },
        {
            id: Pages.ugc,
            title: 'UGC',
            type: 'item',
            icon: IconMessageCircle2,
            breadcrumbs: false,
            url: AppRoutes.UgcList,
        },
        {
            id: Pages.commentsModeration,
            title: 'Comments',
            type: 'item',
            icon: IconMessages,
            breadcrumbs: false,
            url: AppRoutes.CommentsModeration,
        },
        {
            id: Pages.authors,
            title: 'Authors',
            type: 'item',
            icon: IconUsers,
            breadcrumbs: false,
            url: AppRoutes.AuthorList,
        },
        {
            id: Pages.areas,
            title: 'Areas',
            type: 'item',
            icon: IconBorderAll,
            breadcrumbs: false,
            url: AppRoutes.AreaList,
        },
        {
            id: Pages.category,
            title: 'Category',
            type: 'item',
            icon: IconCategory2,
            breadcrumbs: false,
            url: AppRoutes.CategoryList,
        },
        {
            id: Pages.filters,
            title: 'Filters',
            type: 'item',
            icon: IconFilter,
            breadcrumbs: false,
            url: AppRoutes.FiltersList,
        },
        {
            id: Pages.navigationMenu,
            title: 'Navigation Menu',
            type: 'item',
            icon: IconCompass,
            breadcrumbs: false,
            url: AppRoutes.NavigationMenu,
        },
    ],
};

export default cms;
