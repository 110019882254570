import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AuthGuard } from 'shared/utils/route-guard/AuthGuard';
import { DASHBOARD_PATH } from 'shared/config';
import { GuestGuard } from 'shared/utils/route-guard/GuestGuard';
import { MainLayout, MinimalLayout, PermissionGuard } from 'shared/layout';
import Loadable from 'shared/components/Loadable';
import { AppRoutes } from 'shared/constants';
import { Pages, permissionConfig } from 'shared/permission-config';

// AUTH
const AuthLogin = Loadable(lazy(() => import('pages/authentication/authentication/Login')));

// DEFAULT
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/Dashboard')));

// ARTICLE
const ArticleCreatePage = Loadable(lazy(() => import('pages/cms/articles/create')));
const ArticleList = Loadable(lazy(() => import('pages/cms/articles/list')));
const ArticleDetailsPage = Loadable(lazy(() => import('pages/cms/articles/update/details')));
const ArticleCommentsModerationPage = Loadable(lazy(() => import('pages/cms/articles/update/comments')));
const ArticleUpdatePageNavigationWrapper = Loadable(lazy(() => import('features/articles/update-page-navigation-wrapper')));

// CATEGORY
const CategoryList = Loadable(lazy(() => import('pages/cms/category/list')));

// AUTHOR
const AuthorList = Loadable(lazy(() => import('pages/cms/authors/list')));
const AuthorCreatePage = Loadable(lazy(() => import('pages/cms/authors/create')));
const AuthorUpdatePage = Loadable(lazy(() => import('pages/cms/authors/update')));

//FILTER
const FiltersList = Loadable(lazy(() => import('pages/cms/filters/list')));
const FilterCreatePage = Loadable(lazy(() => import('pages/cms/filters/create')));
const FilterUpdatePage = Loadable(lazy(() => import('pages/cms/filters/update')));

// AREA
const AreaListPage = Loadable(lazy(() => import('pages/cms/areas/list')));
const AreaCreatePage = Loadable(lazy(() => import('pages/cms/areas/create')));
const AreaUpdatePage = Loadable(lazy(() => import('pages/cms/areas/update')));

// CATEGORY
const CategoryCreatePage = Loadable(lazy(() => import('pages/cms/category/create')));
const CategoryUpdatePage = Loadable(lazy(() => import('pages/cms/category/update')));

// NAVIGATION MENU
const NavigationMenuPage = Loadable(lazy(() => import('pages/cms/navigation-menu')));

// UGC
const UgcListPage = Loadable(lazy(() => import('pages/cms/ugc/list')));
const UgcCreatePage = Loadable(lazy(() => import('pages/cms/ugc/create')));
const UgcDetailsPage = Loadable(lazy(() => import('pages/cms/ugc/update/details')));
const UgcCommentsModerationPage = Loadable(lazy(() => import('pages/cms/ugc/update/comments')));
const UgcUpdatePageNavigationWrapper = Loadable(lazy(() => import('features/ugc/update-page-navigation-wrapper')));

// COMMENTS
const CommentsModerationPage = Loadable(lazy(() => import('pages/cms/comments-moderation')));

// FUNNEL CONFIG
const FunnelConfigList = Loadable(lazy(() => import('./funnel/config/list')));
const FunnelConfigCreatePage = Loadable(lazy(() => import('./funnel/config/create')));
const FunnelConfigUpdatePage = Loadable(lazy(() => import('./funnel/config/update')));
const FunnelConfigCopyPage = Loadable(lazy(() => import('./funnel/config/copy')));

// PRESETS CONSTRUCTOR
const PresetList = Loadable(lazy(() => import('./constructor/preset/list')));
const PresetCreatePage = Loadable(lazy(() => import('./constructor/preset/create')));
const PresetUpdatePage = Loadable(lazy(() => import('./constructor/preset/update')));
const PresetCopyPage = Loadable(lazy(() => import('./constructor/preset/copy')));

// PRESETS FALLBACK CONSTRUCTOR
const FallbackList = Loadable(lazy(() => import('./constructor/fallback/list')));
const FallbackCreatePage = Loadable(lazy(() => import('./constructor/fallback/create')));
const FallbackUpdatePage = Loadable(lazy(() => import('./constructor/fallback/update')));
const FallbackCopyPage = Loadable(lazy(() => import('./constructor/fallback/copy')));

// PAYMENTS PRODUCTS
const ProductListPage = Loadable(lazy(() => import('./products/list')));
const ProductCreatePage = Loadable(lazy(() => import('./products/create')));
const ProductUpdatePage = Loadable(lazy(() => import('./products/update')));
const ProductCopyPage = Loadable(lazy(() => import('./products/copy')));

// REFUND POLICY
const RefundPolicyListPage = Loadable(lazy(() => import('./refund-policy/list')));
const RefundPolicyCreatePage = Loadable(lazy(() => import('./refund-policy/create')));
const RefundPolicyUpdatePage = Loadable(lazy(() => import('./refund-policy/update')));

// REFUND POLICY CUSTOM LIST
const RefundPolicyCustomListPage = Loadable(lazy(() => import('./refund-policy-custom-list/list')));
const RefundPolicyCustomListUpdatePage = Loadable(lazy(() => import('./refund-policy-custom-list/update')));
const RefundPolicyCustomListCreatePage = Loadable(lazy(() => import('./refund-policy-custom-list/create')));

// MANAGERS LIST
const ManagersListPage = Loadable(lazy(() => import('./managers/list')));
const ManagerCreatePage = Loadable(lazy(() => import('./managers/create')));
const ManagerUpdatePage = Loadable(lazy(() => import('./managers/update')));

// CUSTOMERS LIST
const SuspendedCustomersListPage = Loadable(lazy(() => import('./customer-management/suspended-list')));
const SuspendCustomerPage = Loadable(lazy(() => import('./customer-management/suspend')));

// ERROR
const ErrorPage = Loadable(lazy(() => import('pages/maintenance/Error')));

// LEGAL
const LegalModerationPage = Loadable(lazy(() => import('pages/legal-moderation')));

const Routing = () => useRoutes([
    {
        path: '/',
        element: (
            <AuthGuard>
                <Navigate to={DASHBOARD_PATH} />
            </AuthGuard>
        ),
    },
    // AUTH ROUTES
    {
        path: '/',
        element: (
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        ),
        children: [
            {
                path: AppRoutes.Login,
                element: <AuthLogin />,
            },
        ],
    },
    // MAIN ROUTES
    {
        path: '/',
        element: (
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: AppRoutes.Home,
                element: (
                    <PermissionGuard>
                        <DashboardDefault />
                    </PermissionGuard>
                ),
            },
            // ARTICLE
            {
                path: AppRoutes.ArticleList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.articles].access}>
                        <ArticleList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ArticleCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.articles].create}>
                        <ArticleCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ArticleUpdateRoot,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.articles].update}>
                        <ArticleUpdatePageNavigationWrapper />
                    </PermissionGuard>
                ),
                children: [
                    {
                        path: AppRoutes.ArticleDetailsUpdate,
                        element: (
                            <ArticleDetailsPage />
                        ),
                    }, {
                        path: AppRoutes.ArticleCommentsModeration,
                        element: (
                            <ArticleCommentsModerationPage />
                        ),
                    },
                ],
            },
            // CATEGORY
            {
                path: AppRoutes.CategoryList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.category].update}>
                        <CategoryList />
                    </PermissionGuard>
                ),
            },
            // AUTHOR
            {
                path: AppRoutes.AuthorList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.authors].access}>
                        <AuthorList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.AuthorCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.authors].create}>
                        <AuthorCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.AuthorUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.authors].update}>
                        <AuthorUpdatePage />
                    </PermissionGuard>
                ),
            },
            // AREA
            {
                path: AppRoutes.AreaList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.areas].access}>
                        <AreaListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.AreaCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.areas].create}>
                        <AreaCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.AreaUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.areas].update}>
                        <AreaUpdatePage />
                    </PermissionGuard>
                ),
            },
            // CATEGORY
            {
                path: AppRoutes.CategoryCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.category].create}>
                        <CategoryCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.CategoryUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.category].update}>
                        <CategoryUpdatePage />
                    </PermissionGuard>
                ),
            },
            // FILTERS
            {
                path: AppRoutes.FiltersList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.filters].access}>
                        <FiltersList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FilterCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.filters].create}>
                        <FilterCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FilterUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.filters].update}>
                        <FilterUpdatePage />
                    </PermissionGuard>
                ),
            },
            // UGC
            {
                path: AppRoutes.UgcCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.ugc].create}>
                        <UgcCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.UgcUpdateRoot,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.ugc].update}>
                        <UgcUpdatePageNavigationWrapper />
                    </PermissionGuard>
                ),
                children: [
                    {
                        path: AppRoutes.UgcDetailsUpdate,
                        element: (
                            <UgcDetailsPage />
                        ),
                    }, {
                        path: AppRoutes.UgcCommentsModeration,
                        element: (
                            <UgcCommentsModerationPage />
                        ),
                    },
                ],
            },
            {
                path: AppRoutes.UgcList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.ugc].access}>
                        <UgcListPage />
                    </PermissionGuard>
                ),
            },
            // NAVIGATION MENU
            {
                path: AppRoutes.NavigationMenu,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.navigationMenu].access}>
                        <NavigationMenuPage />
                    </PermissionGuard>
                ),
            },
            // COMMENTS MODERATION
            {
                path: AppRoutes.CommentsModeration,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.commentsModeration].access}>
                        <CommentsModerationPage />
                    </PermissionGuard>
                ),
            },
            // FUNNELT CONFIG
            {
                path: AppRoutes.FunnelConfigList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.funnel].access}>
                        <FunnelConfigList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FunnelConfigCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.funnel].create}>
                        <FunnelConfigCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FunnelConfigUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.funnel].update}>
                        <FunnelConfigUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FunnelConfigCopy,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.funnel].copy}>
                        <FunnelConfigCopyPage />
                    </PermissionGuard>
                ),
            },
            // PRESETS CONSTRUCTOR
            {
                path: AppRoutes.PresetList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.presets].access}>
                        <PresetList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.PresetCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.presets].access}>
                        <PresetCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.PresetUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.presets].update}>
                        <PresetUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.PresetCopy,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.presets].copy}>
                        <PresetCopyPage />
                    </PermissionGuard>
                ),
            },
            // PRESET FALLBACKS CONSTRUCTOR
            {
                path: AppRoutes.FallbackList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.fallback].access}>
                        <FallbackList />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FallbackCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.fallback].access}>
                        <FallbackCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FallbackUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.fallback].update}>
                        <FallbackUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.FallbackCopy,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.fallback].copy}>
                        <FallbackCopyPage />
                    </PermissionGuard>
                ),
            },
            // PRODUCTS
            {
                path: AppRoutes.ProductList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.productList].access}>
                        <ProductListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ProductCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.productList].create}>
                        <ProductCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ProductUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.productList].update}>
                        <ProductUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ProductCopy,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.productList].copy}>
                        <ProductCopyPage />
                    </PermissionGuard>
                ),
            },
            // REFUND POLICY
            {
                path: AppRoutes.RefundPolicyList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].access}>
                        <RefundPolicyListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.RefundPolicyCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].create}>
                        <RefundPolicyCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.RefundPolicyUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].update}>
                        <RefundPolicyUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.RefundPolicyCustomList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].access}>
                        <RefundPolicyCustomListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.RefundPolicyCustomListUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].update}>
                        <RefundPolicyCustomListUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.RefundPolicyCustomListCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.billingAutomation].create}>
                        <RefundPolicyCustomListCreatePage />
                    </PermissionGuard>
                ),
            },
            // SUPERADMIN
            {
                path: AppRoutes.ManagersList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.managersList].access}>
                        <ManagersListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ManagerCreate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.managersList].access}>
                        <ManagerCreatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.ManagerUpdate,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.managersList].access}>
                        <ManagerUpdatePage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.SuspendedCustomersList,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.customersManagement].access}>
                        <SuspendedCustomersListPage />
                    </PermissionGuard>
                ),
            },
            {
                path: AppRoutes.SuspendCustomer,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.customersManagement].access}>
                        <SuspendCustomerPage />
                    </PermissionGuard>
                ),
            },
            // LEGAL MODERATION
            {
                path: AppRoutes.LegalModeration,
                element: (
                    <PermissionGuard acceptedRoles={permissionConfig[Pages.legal].access}>
                        <LegalModerationPage />
                    </PermissionGuard>
                ),
            },
        ],
    },
    // ERROR & 404 ROUTES
    {
        path: '*',
        element: (
            <AuthGuard>
                <ErrorPage />
            </AuthGuard>
        ),
    },
    {
        path: 'error',
        element: (
            <ErrorPage />
        ),
    },
]);

export default Routing;
