import { NavItemType } from 'shared/types';

import cms from './cms';
import constructor from './constructor';
import products from './products';
import managers from './managers';
import customers from './customers';
import legal from './legal';

const menuItems: { items: NavItemType[] } = {
    items: [
        constructor,
        products,
        managers,
        legal,
        customers,
        cms,
    ],
};

export default menuItems;
