import { useEffect, useRef } from 'react';

export const useDisableScrollChange = (refs: React.RefObject<HTMLInputElement>[]) => {
    const refocusTimeout = useRef<number | null>(null);

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            refs.forEach(ref => {
                if (ref.current && document.activeElement === ref.current) {
                    (event.target as HTMLInputElement).blur();

                    // Re-focus the input after the scroll event
                    refocusTimeout.current = window.setTimeout(() => {
                        (event.target as HTMLInputElement).focus();
                    }, 0);
                }
            });
        };

        refs.forEach(ref => {
            ref.current?.addEventListener('wheel', handleWheel);
        });

        return () => {
            refs.forEach(ref => {
                ref.current?.removeEventListener('wheel', handleWheel);
            });

            if (refocusTimeout.current !== null) {
                clearTimeout(refocusTimeout.current);
            }
        };
    }, [refs]);
};
