import { FormattedMessage } from 'react-intl';
import { Pages } from 'shared/permission-config';
import { AppRoutes } from 'shared/constants';
import PolicyIcon from '@mui/icons-material/Policy';

const legal = {
    id: 'legal',
    title: <FormattedMessage id="Legal" />,
    type: 'group',
    children: [
        {
            id: Pages.legal,
            title: 'Legal',
            type: 'item',
            url: AppRoutes.LegalModeration,
            icon: PolicyIcon,
            breadcrumbs: false,
        },
    ],
};

export default legal;
