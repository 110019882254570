import { memoize } from 'lodash';
import { createEffect, createEvent, createStore, sample } from 'effector';
import Cookies, { CookieSetOptions } from 'universal-cookie';

interface CookiesSet {
    name: string;
    value: string | number | boolean | null | undefined;
    attributes?: CookieSetOptions;
}

type CookiesRemove = Omit<CookiesSet, 'value'>;

const parseCookie = memoize(str => {
    if (str === '') {
        return {};
    } else {
        return str && str
            ?.split(';')
            .map(v => v.split('='))
            .reduce((acc, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());

                return acc;
            }, {});
    }
});

const $cookies = createStore<Record<string, string>>({});
const getAllEv = createEvent();
const setEv = createEvent<CookiesSet>();
const setBulkEv = createEvent<CookiesSet[]>();
const removeEv = createEvent<CookiesRemove>();

const cookies = new Cookies();

const getAllFx = createEffect(() => parseCookie(document.cookie) ?? {});

const $isReady = createStore(false)
    .on(getAllFx.doneData, () => true);

const setFx = createEffect(({ name, value, attributes }: CookiesSet) => {
    if (attributes) {
        return cookies.set(name, value, attributes);
    }

    return cookies.set(name, value);
});

const setBulkFx = createEffect((items: CookiesSet[]) => {
    items.forEach(({ name, value, attributes }) => {
        if (attributes) {
            return cookies.set(name, value, attributes);
        }

        return cookies.set(name, value);
    });
});

sample({
    clock: setEv,
    target: setFx,
});

sample({
    clock: setBulkEv,
    target: setBulkFx,
});

const removeFx = createEffect(({ name, attributes }: CookiesRemove) => {
    if (attributes) {
        return cookies.remove(name, { ...attributes, path: attributes?.path || '/', maxAge: attributes?.maxAge || 0 });
    }

    return cookies.remove(name, { path: '/', maxAge: 0 });
});

sample({
    clock: removeEv,
    target: removeFx,
});

sample({
    clock: getAllEv,
    target: getAllFx,
});

sample({
    clock: getAllFx.doneData,
    target: $cookies,
});

sample({
    clock: [setFx, setBulkFx, removeFx],
    target: getAllFx,
});

export const cookiesService = {
    $cookies,
    $isReady,
    setEv,
    setBulkEv,
    removeEv,
    getAllEv,
    getAllFx,
};

export { type CookiesSet, type CookiesRemove };
