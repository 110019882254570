import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { useLayoutConfig } from 'shared/hooks';
import SubCard from 'shared/components/cards/SubCard';

const BoxContainer = () => {
    const { container, onChangeContainer } = useLayoutConfig();

    return (
        <SubCard title="Box Container">
            <FormControl
                component="fieldset"
                sx={{ mt: 2 }}
            >
                <FormControlLabel
                    control={(
                        <Switch
                            checked={container}
                            onChange={() => onChangeContainer()}
                            inputProps={{ 'aria-label': 'controlled-direction' }}
                        />
                    )}
                    label="Container"
                />
            </FormControl>
        </SubCard>
    );
};

export default BoxContainer;
