enum ArticleUpdatePageRoutes {
    Details = 'details',
    Comments = 'comments'
}

enum UgcUpdatePageRoutes {
    Details = 'details',
    Comments = 'comments'
}

enum AppRoutes {
    Login = '/login',
    Home = '/dashboard',
    // ARTICLE
    ArticleList = '/cms/articles',
    ArticleCreate = '/cms/articles/create',
    ArticleUpdateRoot = '/cms/articles/:articleId',
    ArticleDetailsUpdate = '/cms/articles/:articleId/details',
    ArticleCommentsModeration = '/cms/articles/:articleId/comments',
    // AUTHOR
    AuthorList = '/cms/authors',
    AuthorCreate = '/cms/authors/create',
    AuthorUpdate = '/cms/authors/:authorId/edit',
    // AREA
    AreaList = '/cms/areas',
    AreaCreate = '/cms/areas/create',
    AreaUpdate = '/cms/areas/:areaId/edit',
    // CATEGORY
    CategoryList = '/cms/category',
    CategoryCreate = '/cms/category/create',
    CategoryUpdate = '/cms/category/:categoryId/edit',
    // FILTERS
    FiltersList = '/cms/filters',
    FilterCreate = '/cms/filters/create',
    FilterUpdate = '/cms/filters/:filterId/edit',
    // NAVIGATION MENU
    NavigationMenu = '/cms/navigation-menu',
    // UGC
    UgcList = '/cms/ugc',
    UgcCreate = '/cms/ugc/create',
    UgcUpdateRoot = '/cms/ugc/:ugcId',
    UgcDetailsUpdate = '/cms/ugc/:ugcId/details',
    UgcCommentsModeration = '/cms/ugc/:ugcId/comments',
    // COMMENTS
    CommentsModeration = '/cms/comments',
    // LEGAL
    LegalModeration = '/legal',
    // PRESETS CONSTRUCTOR
    PresetList = '/constructor/preset',
    PresetCreate = '/constructor/preset/create',
    PresetCopy = '/constructor/preset/:presetId/copy',
    PresetUpdate = '/constructor/preset/:presetId/edit',
    // PRESET FALLBACKS CONSTRUCTOR
    FallbackList = '/constructor/fallback',
    FallbackCreate = '/constructor/fallback/create',
    FallbackCopy = '/constructor/fallback/:fallbackId/copy',
    FallbackUpdate = '/constructor/fallback/:fallbackId/edit',
    // PAYMENTS PRODUCTS
    ProductList = '/product/list',
    ProductCreate = '/product/create',
    ProductUpdate = '/product/:productId/edit',
    ProductCopy = '/product/:productId/copy',
    // REFUND POLICY
    RefundPolicyList = '/refund-policy/:refundPolicyVariant/list',
    RefundPolicyCreate = '/refund-policy/:refundPolicyVariant/create',
    RefundPolicyUpdate = '/refund-policy/:refundPolicyId/update',
    // REFUND POLICY CUSTOM LIST
    RefundPolicyCustomList = '/refund-policy/custom-list/list',
    RefundPolicyCustomListUpdate = '/refund-policy/custom-list/:customListId/edit',
    RefundPolicyCustomListCreate = '/refund-policy/custom-list/create',
    // SUPERADMIN
    ManagersList = '/managers/list',
    ManagerCreate = '/manager/create',
    ManagerUpdate = '/manager/:managerId/edit',
    // CUSTOMERS
    SuspendedCustomersList = '/customer/suspended/list',
    SuspendCustomer = '/customer/suspend',
    // FUNNEL
    FunnelConfigList = '/funnel/config',
    FunnelConfigCreate = '/funnel/config/create',
    FunnelConfigCopy = '/funnel/config/:funnelConfigId/copy',
    FunnelConfigUpdate = '/funnel/config/:funnelConfigId/edit',
}

export { AppRoutes, ArticleUpdatePageRoutes, UgcUpdatePageRoutes };
