import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography, TextField, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ProfileSection from 'shared/layout/MainLayout/Header/ProfileSection';
import { IconMenu2 } from '@tabler/icons';
import { menuModel } from 'entities/menu';
import { useUnit } from 'effector-react';
import LogoSection from 'shared/layout/MainLayout/LogoSection';
import { useAppConfig, useTenant } from 'shared/hooks';
import { tenantModel } from 'shared/models/tenant';
import { tenantList } from 'shared/config';
import { Tenant } from 'shared/types/tenant';
import { TextFieldOnChangeEvent } from 'shared/types';

const Header = () => {
    const theme = useTheme();
    const { title } = useAppConfig();
    const { pathname } = useLocation();

    const [
        drawerOpen,
        tenant,
        isDisabled,
        setTenant,
        reloadPage,
    ] = useUnit([
        menuModel.$drawerOpen,
        tenantModel.stores.$tenant,
        tenantModel.stores.$isDisabled,
        tenantModel.events.setTenantEv,
        tenantModel.events.reloadPageEv,
    ]);

    useTenant();

    const handleTenantChange = (event: TextFieldOnChangeEvent) => {
        setTenant(event.target.value as Tenant);
        reloadPage();
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                [theme.breakpoints.down('md')]: {
                    width: 'auto',
                },
            }}
            >
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: 1,
                    }}
                >
                    <LogoSection />
                    <Typography
                        fontWeight="bold"
                        fontSize={30}
                        fontFamily="Poppins"
                    >
                        {title}
                    </Typography>
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
                        },
                    }}
                    onClick={() => menuModel.openDrawerEv(!drawerOpen)}
                    color="inherit"
                >
                    <IconMenu2
                        stroke={1.5}
                        size="20px"
                    />
                </Avatar>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {!pathname.includes('/product/') && (
                <TextField
                    id="tenant"
                    name="tenant"
                    select
                    fullWidth
                    label="Tenant"
                    value={tenant}
                    onChange={handleTenantChange}
                    disabled={isDisabled}
                    style={{
                        width: '200px',
                        marginRight: '20px',
                    }}
                >
                    {tenantList.map(tenant => (
                        <MenuItem
                            key={tenant}
                            value={tenant}
                        >
                            {tenant}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <ProfileSection />
        </>
    );
};

export default Header;
