import { createEvent, createStore, sample, createEffect } from 'effector';
import { GrowthbookFeatures } from 'shared/types/growthbook-features';

const setFeaturesEv = createEvent();

const $features = createStore<GrowthbookFeatures[]>([]);
const $growthbookOffset = createStore(0);

const getGrowthbookFeaturesFx = createEffect(async offset => {
    const response = await fetch(`https://api.growthbook.io/api/v1/features?limit=100&offset=${offset}`, {
        headers: { Authorization: 'Bearer secret_readonly_BBvFoQJySbLrjMO403Zbi8MxUHouaSpjRjWz6HfUrA' },
    });

    const growthbook = await response.json();

    return growthbook;
});

sample({
    clock: setFeaturesEv,
    source: $growthbookOffset,
    target: getGrowthbookFeaturesFx,
});

sample({
    clock: getGrowthbookFeaturesFx.doneData,
    source: {
        currentFeatures: $features,
        offset: $growthbookOffset,
    },
    fn: ({ currentFeatures, offset }, { features }) => {
        if (offset) {
            return [
                ...currentFeatures,
                ...features,
            ];
        }

        return features;

    },
    target: $features,
});

sample({
    clock: getGrowthbookFeaturesFx.doneData,
    source: $growthbookOffset,
    filter: (_, { nextOffset }) => nextOffset,
    fn: (offset, { nextOffset }) => nextOffset ? nextOffset : offset,
    target: [$growthbookOffset, getGrowthbookFeaturesFx],
});

export const growthbookFeaturesModal = {
    events: {
        setFeaturesEv,
    },
    stores: {
        $features,
    },
};
