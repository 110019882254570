export const slugify = (slug: string) => {
    let res = slug
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '-')
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');

    if (res.startsWith('-')) {
        res = res.substring(1);
    }

    if (res.endsWith('-')) {
        res = res.substring(0, res.length - 1);
    }

    return res;
};
