import { createEvent, createStore, restore, sample } from 'effector';
import { NavigateFunction, URLSearchParamsInit } from 'react-router-dom';
import { spread } from 'patronum';

type SetURLSearchParams = (nextInit: URLSearchParamsInit, navigateOptions?: ({ replace?: boolean | undefined; state?: any } | undefined)) => void;

const attachNavigateEv = createEvent<NavigateFunction>();
const attachSearchParamsEv = createEvent<{ searchParams: URLSearchParams; setSearchParams: SetURLSearchParams }>();

const $navigate = restore(attachNavigateEv, null);
const $searchParams = createStore<URLSearchParams | null>(null);
const $setSearchParams = createStore<SetURLSearchParams | null>(null);

sample({
    clock: attachSearchParamsEv,
    target: spread({
        targets: {
            searchParams: $searchParams,
            setSearchParams: $setSearchParams,
        },
    }),
});

export const routerModel = {
    $navigate,
    $searchParams,
    $setSearchParams,
    attachNavigateEv,
    attachSearchParamsEv,
};
