import { createContext, ReactNode, useMemo } from 'react';
import { useLocalStorage } from 'shared/hooks';
import defaultConfig from 'shared/config';
import { PaletteMode } from '@mui/material';
import { CustomizationProps } from 'shared/types/config';

const initialState: CustomizationProps = {
    ...defaultConfig,
    onChangeMenuType: () => {
    },
    onChangePresetColor: () => {
    },
    onChangeLocale: () => {
    },
    onChangeContainer: () => {
    },
    onChangeFontFamily: () => {
    },
    onChangeBorderRadius: () => {
    },
    onChangeOutlinedField: () => {
    },
};

const PreferencesContext = createContext(initialState);

interface ConfigProviderProps {
    children: ReactNode;
}

const PreferencesProvider = ({ children }: ConfigProviderProps) => {
    const [config, setConfig] = useLocalStorage('berry-config', {
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        navType: initialState.navType,
        presetColor: initialState.presetColor,
        locale: initialState.locale,
    });

    const configContextValue = useMemo(() => {
        const onChangeMenuType = (navType: PaletteMode) => {
            setConfig({
                ...config,
                navType,
            });
        };

        const onChangePresetColor = (presetColor: string) => {
            setConfig({
                ...config,
                presetColor,
            });
        };

        const onChangeLocale = (locale: string) => {
            setConfig({
                ...config,
                locale,
            });
        };

        const onChangeContainer = () => {
            setConfig({
                ...config,
                container: !config.container,
            });
        };

        const onChangeFontFamily = (fontFamily: string) => {
            setConfig({
                ...config,
                fontFamily,
            });
        };

        const onChangeBorderRadius = (_, newValue: number | number[]) => {
            setConfig({
                ...config,
                borderRadius: newValue as number,
            });
        };

        const onChangeOutlinedField = (outlinedFilled: boolean) => {
            setConfig({
                ...config,
                outlinedFilled,
            });
        };

        return {
            ...config,
            onChangeMenuType,
            onChangePresetColor,
            onChangeLocale,
            onChangeContainer,
            onChangeFontFamily,
            onChangeBorderRadius,
            onChangeOutlinedField,
        };
    }, [config, setConfig]);

    return (
        <PreferencesContext.Provider value={configContextValue}>
            {children}
        </PreferencesContext.Provider>
    );
};

export { PreferencesProvider, PreferencesContext };
