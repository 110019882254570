import { Role } from 'shared/types/auth';

export enum Pages {
    areas = 'areas',
    articles = 'articles',
    authors = 'authors',
    filters = 'filters',
    navigationMenu = 'navigationMenu',
    ugc = 'ugc',
    category = 'category',
    presets = 'presets',
    fallback = 'fallback',
    productList = 'productList',
    billingAutomation = 'billingAutomation',
    refundPolicy1 = 'refundPolicy1',
    refundPolicy2 = 'refundPolicy2',
    refundPolicyCustomList = 'refundPolicyCustomList',
    commentsModeration = 'commentsModeration',
    managersList = 'managersList',
    customersManagement = 'customersManagement',
    legal = 'legal',
    funnel = 'funnel',
}

/**
 * Permissions config for CMS app logic
 */
const cmsPermissions = {
    [Pages.areas]: {
        access: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
    },
    [Pages.articles]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.category]: {
        access: [Role.SEO_ADMIN, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SEO_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.authors]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.filters]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SUPER_ADMIN],
        updateAdditionalData: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
    },
    [Pages.navigationMenu]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SUPER_ADMIN],
        areas: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
        filters: [Role.SEO_ADMIN, Role.SUPER_ADMIN],
        filterValues: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.ugc]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        create: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
        update: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.commentsModeration]: {
        access: [Role.SEO_ADMIN, Role.SEO_SENIOR_EDITOR, Role.SEO_EDITOR, Role.SUPER_ADMIN],
    },
};

/**
 * Permissions config for Product-Marketing app logic
 */
const productMarketingPermissions = {
    [Pages.funnel]: {
        access: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        create: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        update: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        copy: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.productList]: {
        access: [Role.PAYMENTS_PRODUCTS_EDITOR, Role.SUPER_ADMIN],
        create: [Role.PAYMENTS_PRODUCTS_EDITOR, Role.SUPER_ADMIN],
        update: [Role.PAYMENTS_PRODUCTS_EDITOR, Role.SUPER_ADMIN],
        copy: [Role.PAYMENTS_PRODUCTS_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.billingAutomation]: {
        access: [Role.PAYMENTS_MANAGER, Role.SUPER_ADMIN],
        create: [Role.PAYMENTS_MANAGER, Role.SUPER_ADMIN],
        update: [Role.PAYMENTS_MANAGER, Role.SUPER_ADMIN],
        copy: [Role.PAYMENTS_MANAGER, Role.SUPER_ADMIN],
    },
    [Pages.managersList]: {
        access: [Role.SUPER_ADMIN],
        create: [Role.SUPER_ADMIN],
        update: [Role.SUPER_ADMIN],
    },
    [Pages.customersManagement]: {
        access: [Role.CUSTOMER_MANAGER, Role.SUPER_ADMIN],
    },
    [Pages.presets]: {
        access: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        create: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        update: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        copy: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
    },
    [Pages.fallback]: {
        access: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        create: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        update: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
        copy: [Role.LANDINGS_EDITOR, Role.SUPER_ADMIN],
    },
};

/**
 * Permissions config for Legal app logic
 */
const legalPermissions = {
    [Pages.legal]: {
        access: [Role.SUPER_ADMIN, Role.LEGAL_ENTITY_EDITOR],
    },
};

export const permissionConfig = {
    ...cmsPermissions,
    ...productMarketingPermissions,
    ...legalPermissions,
};
