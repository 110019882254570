import { GuardProps } from 'shared/types';
import { useUnit } from 'effector-react';
import { authModel } from 'entities/auth';
import { routerModel } from 'shared/models/router';
import { Suspense, useEffect } from 'react';
import Loader from 'shared/components/Loader';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const isNotAuthorized = useUnit(authModel.$isNotAuthorized);
    const isLoading = useUnit(authModel.$isLoading);
    const navigate = useUnit(routerModel.$navigate);

    useEffect(() => {
        if (isNotAuthorized) {
            navigate?.('login', { replace: true });
        }
    }, [isNotAuthorized, navigate]);

    if (isLoading) {
        return <Suspense fallback={<Loader />} />;
    }

    return children;
};

export { AuthGuard };
