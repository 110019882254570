import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import Breadcrumbs from 'shared/components/extended/Breadcrumbs';
import navigation from 'shared/menu-items';
import { useLayoutConfig } from 'shared/hooks';
import { IconChevronRight } from '@tabler/icons';
import { menuModel } from 'entities/menu';
import { useUnit } from 'effector-react';
import { DRAWER_WIDTH } from 'shared/constants';
import { Customization } from 'shared/layout';

import Header from './Header';
import Sidebar from './Sidebar';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(DRAWER_WIDTH - 20),
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    }),
}));

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const drawerOpen = useUnit(menuModel.$drawerOpen);
    const { container } = useLayoutConfig();

    useEffect(() => {
        menuModel.openDrawerEv(!matchDownMd);
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none',
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main
                theme={theme}
                open={drawerOpen}
            >
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs
                            separator={IconChevronRight}
                            navigation={navigation}
                            icon
                            title
                            rightAlign
                        />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs
                            separator={IconChevronRight}
                            navigation={navigation}
                            icon
                            title
                            rightAlign
                        />
                        <Outlet />
                    </>
                )}
            </Main>
            <Customization />
        </Box>
    );
};

export { MainLayout };
