/**
 * @hoc Trigger out effector logic on app start
 */

import { useNavigate, useSearchParams } from 'react-router-dom';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { routerModel } from 'shared/models/router';
import { useUnit } from 'effector-react';
import { authModel } from 'entities/auth';

let timer: number;

const WithEffector = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        routerModel.attachNavigateEv(navigate);
        routerModel.attachSearchParamsEv({ searchParams, setSearchParams });
    }, [navigate, searchParams, setSearchParams]);

    // refresh access token
    const refreshToken = authModel.refreshTokenEv;
    const isAuthenticated = useUnit(authModel.$isAuthorized);
    const isLoading = useUnit(authModel.$isLoading);

    const minutes = 1;
    const refreshTime = 1000 * 60 * minutes;

    const timeoutRefresh = useCallback(
        () => {
            refreshToken();
            clearTimeout(timer);
            timer = window.setTimeout(timeoutRefresh, refreshTime);
        }, [refreshTime, refreshToken]
    );

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            clearTimeout(timer);
            timer = window.setTimeout(timeoutRefresh, refreshTime);
        }

        const handler = () => {
            if (document.visibilityState === 'visible') {
                clearTimeout(timer);
                refreshToken();
                timer = window.setTimeout(timeoutRefresh, refreshTime);
            } else {
                clearTimeout(timer);
            }
        };

        document.addEventListener('visibilitychange', handler);

        return () => {
            document.removeEventListener('visibilitychange', handler);
        };
    }, [isAuthenticated, isLoading, refreshTime, refreshToken, timeoutRefresh]);

    return (
        <>
            {children}
        </>
    );
};

export default WithEffector;
