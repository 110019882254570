import { useUnit } from 'effector-react';
import { ReactElement, Suspense, useEffect } from 'react';
import { isHasPermission } from 'shared/utils/isHasPermission';
import { Role } from 'shared/types/auth';
import { routerModel } from 'shared/models/router';
import Loader from 'shared/components/Loader';
import { $userRole } from 'shared/models/user-info';
import { authModel } from 'entities/auth';
import { menuModel } from 'entities/menu';

interface Props {
    acceptedRoles?: Role[];
    children: ReactElement;
}

export const PermissionGuard = ({ acceptedRoles = [], children }: Props) => {
    const userRole = useUnit($userRole);
    const navigate = useUnit(routerModel.$navigate);
    const isLoading = useUnit(authModel.$isLoading);
    const hasPermission = acceptedRoles.length === 0 ? true : isHasPermission(acceptedRoles, userRole);

    useEffect(() => {
        if (!hasPermission) {
            menuModel.activeItemEv(['']);

            return navigate?.('/', { replace: true });
        }
    }, [acceptedRoles, hasPermission, navigate]);

    if (isLoading) {
        return <Suspense fallback={<Loader />} />;
    }

    return hasPermission ? children : null;
};

