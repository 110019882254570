import React, { useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { useLayoutConfig } from 'shared/hooks';

const loadLocaleData = (locale: string) => {
    switch (locale) {
        default:
            return import('shared/utils/locales/en.json');
    }
};

interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const { locale } = useLayoutConfig();
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        loadLocaleData(locale).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider
                    locale={locale}
                    defaultLocale="en"
                    messages={messages}
                >
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export { Locales };
