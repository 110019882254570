import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useAppConfig } from 'shared/hooks';
import { AuthCheck, NavigationScroll } from 'shared/layout';
import Routing from 'pages';
import { Snackbar } from 'entities/snackbar';
import { Locales } from 'entities/localization';
import WithEffector from 'app/providers/with-effector';
import { growthbookFeaturesModal } from 'shared/models/growthbook-features';
import { useUnit } from 'effector-react';

import { withProviders } from './providers';

import 'shared/assets/scss/style.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/**
 * App's entry-point
 * @remark Contains application initialization logic in a HOC wrapper
 * @see withProviders
 */
const App = () => {
    const setFeatures = useUnit(growthbookFeaturesModal.events.setFeaturesEv);
    const { logo } = useAppConfig();

    useEffect(() => {
        setFeatures();
    }, [setFeatures]);

    return (
        <>
            <Helmet>
                <link
                    rel="icon"
                    href={logo}
                />
            </Helmet>
            <WithEffector>
                <AuthCheck>
                    <Locales>
                        <NavigationScroll>
                            <>
                                <Routing />
                                <Snackbar />
                            </>
                        </NavigationScroll>
                    </Locales>
                </AuthCheck>
            </WithEffector>
        </>
    );
};

export default withProviders(App);
