import { IconList } from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

const billingAutomation = {
    id: Pages.billingAutomation,
    title: 'Billing automation',
    type: 'collapse',
    icon: IconList,
    breadcrumbs: false,
    children: [
        {
            id: Pages.refundPolicy1,
            title: 'Refund policy 1',
            type: 'item',
            breadcrumbs: false,
            url: AppRoutes.RefundPolicyList,
            params: {
                refundPolicyVariant: 1,
            },
        },
        {
            id: Pages.refundPolicy2,
            title: 'Refund policy 2',
            type: 'item',
            breadcrumbs: false,
            url: AppRoutes.RefundPolicyList,
            params: {
                refundPolicyVariant: 2,
            },
        },
        {
            id: Pages.refundPolicyCustomList,
            title: 'Custom list',
            type: 'item',
            breadcrumbs: false,
            url: AppRoutes.RefundPolicyCustomList,
        },
    ],
};

export default billingAutomation;
