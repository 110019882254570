import { FormattedMessage } from 'react-intl';
import { IconAd, IconMessageChatbot, IconFall } from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

const constructor = {
    id: 'constructor',
    title: <FormattedMessage id="Constructor" />,
    type: 'group',
    children: [
        {
            id: Pages.funnel,
            title: 'Funnel Config',
            type: 'item',
            url: AppRoutes.FunnelConfigList,
            icon: IconAd,
            breadcrumbs: false,
        },
        {
            id: Pages.presets,
            title: 'Presets',
            type: 'item',
            icon: IconMessageChatbot,
            breadcrumbs: false,
            url: AppRoutes.PresetList,
        },
        {
            id: Pages.fallback,
            title: 'Fallbacks',
            type: 'item',
            icon: IconFall,
            breadcrumbs: false,
            url: AppRoutes.FallbackList,
        },
    ],
};

export default constructor;
