import { useMemo, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { tenantModel } from 'shared/models/tenant';
import { Tenant } from 'shared/types/tenant';
import { cookiesService } from 'shared/cookies';

const DEFAULT_TENANT_NAME = 'howly';

const AppConfigs: Record<'ask-crew' | 'howly', Tenant> = {
    howly: 'howly',
    'ask-crew': 'ask_crew',
};

export const useTenant = () => {
    const [
        cookies,
        getAllCookie,
        setTenant,
        reloadPage,
    ] = useUnit([
        cookiesService.$cookies,
        cookiesService.getAllEv,
        tenantModel.events.setTenantEv,
        tenantModel.events.reloadPageEv,
    ]);

    useEffect(() => {
        if (cookies.tenant && Object.values(cookies).length) {
            setTenant(cookies.tenant as Tenant);
        }
    }, [cookies, setTenant, reloadPage]);

    useEffect(() => {
        getAllCookie();
    }, [getAllCookie]);

    return useMemo(() => {
        const domainRegex = /ask-crew|howly/g;
        const appName = window.location.host.match(domainRegex)?.[0] || DEFAULT_TENANT_NAME;

        if (!cookies.tenant && Object.values(cookies).length) {
            setTenant(AppConfigs[appName]);
        }

    }, [setTenant, cookies]);

};
