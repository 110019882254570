import { ContextType, useCallback, useContext, useEffect } from 'react';
import type { Blocker, History, Transition } from 'history';
import { Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

interface Navigator extends BaseNavigator {
    block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
    navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
const useBlocker = (blocker: Blocker, when = true) => {
    const { navigator } = useContext(
        NavigationContext
    ) as NavigationContextWithBlock;

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        // eslint-disable-next-line consistent-return
        return unblock;
    }, [navigator, blocker, when]);
};

type UsePromptFunc = (cb: (tx: Transition) => void, condition: boolean) => void;

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
const usePrompt: UsePromptFunc = (cb, condition = true) => {
    const blocker = useCallback(
        (tx: Transition) => {
            cb(tx);
        },
        [cb]
    );

    return useBlocker(blocker, condition);
};

export { usePrompt };
