import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { BASE_PATH } from 'shared/config';

/**
 * @hoc Initialize react-router-dom provider
 */
const withRouter = (component: Component) => () => (
    <BrowserRouter basename={BASE_PATH}>
        {component()}
    </BrowserRouter>
);

export default withRouter;
