import React, { ReactElement, useEffect } from 'react';
import { authModel } from 'entities/auth';
import { useUnit } from 'effector-react';
import styled from 'styled-components';
import Loader from 'shared/components/Loader';

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`;

const LoaderBox = styled.div<{ $isVisible: boolean }>`
    position: ${({ $isVisible: isVisible }) => isVisible ? 'block' : 'absolute'};
    height: ${({ $isVisible: isVisible }) => isVisible ? 'auto' : 0};
    opacity: ${({ $isVisible: isVisible }) => isVisible ? 1 : 0};
    visibility: ${({ $isVisible: isVisible }) => isVisible ? 'visible' : 'hidden'};
`;

const AuthCheck = ({ children }: { children: ReactElement | null }) => {
    const authCheckPending = useUnit(authModel.$authCheckPending);

    useEffect(() => {
        authModel.checkAuthEv();
    }, []);

    return (
        <Root>
            {children}
            <LoaderBox $isVisible={authCheckPending}>
                <Loader />
            </LoaderBox>
        </Root>
    );
};

export { AuthCheck };
