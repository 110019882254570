import { DASHBOARD_PATH } from 'shared/config';
import { GuardProps } from 'shared/types';
import { Suspense, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { authModel } from 'entities/auth';
import { routerModel } from 'shared/models/router';
import Loader from 'shared/components/Loader';

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const isAuthorized = useUnit(authModel.$isAuthorized);
    const navigate = useUnit(routerModel.$navigate);
    const isLoading = useUnit(authModel.$isLoading);

    useEffect(() => {
        if (isAuthorized) {
            navigate?.(DASHBOARD_PATH, { replace: true });
        }
    }, [isAuthorized, navigate]);

    if (isLoading) {
        return <Suspense fallback={<Loader />} />;
    }

    return children;
};

export { GuestGuard };
