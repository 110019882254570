import { FormattedMessage } from 'react-intl';
import { IconList } from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

const customers = {
    id: 'customer',
    title: <FormattedMessage id="Customers" />,
    type: 'group',
    children: [
        {
            id: Pages.customersManagement,
            title: 'Suspended customers',
            type: 'item',
            icon: IconList,
            breadcrumbs: false,
            url: AppRoutes.SuspendedCustomersList,
        },
    ],
};

export default customers;
