import { NavItemParams } from 'shared/types';

/**
 * Interpolate a URL template by replacing parameters with values.
 *
 * @param route The template string for the route, which includes placeholders prefixed with ':', e.g., '/user/:userId'.
 * @param params An object containing key-value pairs where each key matches a placeholder in the route without the ':' prefix, and the value is the replacement for that placeholder.
 * @returns A function obtained by generating the route pass with parameters.
 * For example, `interpolateRouteWithParams('/user/:userId', {userId: '1'})` is return string: '/user/1'.
 */

const interpolateRouteWithParams = (route: string, params: NavItemParams): string => Object
    .entries(params)
    .reduce((composedRoute, [paramName, paramValue]) => composedRoute.replace(':' + paramName, paramValue), route);

export { interpolateRouteWithParams };
