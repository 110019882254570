import React from 'react';

import ThemeCustomization from './themes';

/**
 * @hoc Initialize Muit5 theme and styles provider
 */
const withMui5 = (component: Component) => () => (
    <ThemeCustomization>
        {component()}
    </ThemeCustomization>
);

export default withMui5;
