import { useMemo } from 'react';
import howlyIcon from 'shared/assets/images/icons/howly.svg';
import askCrew from 'shared/assets/images/icons/ask-crew.svg';

interface AppConfigValue {
    logo: any;
    title: string;
}

const DEFAULT_APP_NAME = 'howly';

const AppConfigs: Record<'ask-crew' | 'howly', AppConfigValue> = {
    howly: {
        logo: howlyIcon,
        title: 'Howly',
    },
    'ask-crew': {
        logo: askCrew,
        title: 'Ask-Crew',
    },
};

/**
 * @hook for app configuration depending on domain
 */
const useAppConfig = () => useMemo(() => {
    const domainRegex = /ask-crew|howly/g;
    const appName = window.location.host.match(domainRegex)?.[0] || DEFAULT_APP_NAME;

    return AppConfigs[appName] as AppConfigValue;
}, []);

export { useAppConfig };
