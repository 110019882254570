import { FormattedMessage } from 'react-intl';
import { IconList } from '@tabler/icons';
import { AppRoutes } from 'shared/constants/routes';
import { Pages } from 'shared/permission-config';

import billingAutomation from './billingAutomation';

const products = {
    id: 'payments',
    title: <FormattedMessage id="Payments" />,
    type: 'group',
    children: [
        {
            id: Pages.productList,
            title: 'Products',
            type: 'item',
            icon: IconList,
            breadcrumbs: false,
            url: AppRoutes.ProductList,
        },
        billingAutomation,
    ],
};

export default products;
