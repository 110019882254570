import { SERVICE_HOST } from 'shared/config';
import { AuthService } from '@howly/howly-backend-api-client-v2/howly/api/manager/back_office/v1/auth_connect';
import { ManagerService } from '@howly/howly-backend-api-client-v2/howly/api/manager/back_office/v1/manager_connect';
import { CustomerManagementService } from '@howly/howly-backend-api-client-v2/howly/api/customer/back_office/v1/customer_management_connect';
import { ArticleService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/article_connect';
import { ImageService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/image_service_connect';
import { AuthorService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/author_connect';
import { FilterService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/filter_connect';
import { AreaService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/area_connect';
import {
    FunnelConfigService,
} from '@howly/howly-backend-api-client-v2/howly/api/funnel/back_office/v1/funnel_config_connect';
import {
    NavigationMenuService,
} from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/navigation_menu_connect';
import { UGCService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/ugc_connect';
import { PresetService } from '@howly/howly-backend-api-client-v2/howly/api/funnel/back_office/v1/preset_connect';
import { PresetFallbackService } from '@howly/howly-backend-api-client-v2/howly/api/funnel/back_office/v1/fallback_connect';
import { CategoryService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/category_connect';
import { ProductService } from '@howly/howly-backend-api-client-v2/howly/api/payments/back_office/v1/product_connect';
import { RefundPolicyRuleService } from '@howly/howly-backend-api-client-v2/howly/api/payments/back_office/v1/refund_policy_rule_connect';
import { CustomListService } from '@howly/howly-backend-api-client-v2/howly/api/payments/back_office/v1/custom_lists_connect';
import { UpsellService } from '@howly/howly-backend-api-client-v2/howly/api/payments/back_office/v1/upsell_connect';
import {
    CommentsModerationService,
} from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/comments_moderation_connect';
import { LegalService } from '@howly/howly-backend-api-client-v2/howly/api/cms/back_office/v1/legal_connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient, Interceptor } from '@connectrpc/connect';
import Cookies from 'universal-cookie';

import { $accessToken } from './user-info';

const cookies = new Cookies();

const addTenantHeaderInterceptor: Interceptor = next => async req => {
    const domainRegex = /ask-crew|howly/g;
    const appName = window.location.host.match(domainRegex)?.[0].replace(/-/g, '_') || 'howly';

    req.header.set('X-TENANT', cookies.get('tenant') ?? appName);

    return await next(req);
};

const createAddAuthorizationHeaderInterceptor = (accessToken: string): Interceptor => next => async req => {
    req.header.set('Authorization', `Bearer ${accessToken}`);

    return await next(req);
};

const $transport = $accessToken.map(
    accessToken => createGrpcWebTransport({
        baseUrl: SERVICE_HOST,
        useBinaryFormat: true,
        interceptors: [
            addTenantHeaderInterceptor,
            createAddAuthorizationHeaderInterceptor(accessToken),
        ],
    })
);

// CMS
export const $authService = $transport.map(transport => createPromiseClient(AuthService, transport));
export const $articleService = $transport.map(transport => createPromiseClient(ArticleService, transport));
export const $imageService = $transport.map(transport => createPromiseClient(ImageService, transport));
export const $authorService = $transport.map(transport => createPromiseClient(AuthorService, transport));
export const $filtersService = $transport.map(transport => createPromiseClient(FilterService, transport));
export const $areaService = $transport.map(transport => createPromiseClient(AreaService, transport));
export const $categoryService = $transport.map(transport => createPromiseClient(CategoryService, transport));
export const $navigationMenuService = $transport.map(transport => createPromiseClient(NavigationMenuService, transport));
export const $ugcService = $transport.map(transport => createPromiseClient(UGCService, transport));
export const $commentsModerationService = $transport.map(transport => createPromiseClient(CommentsModerationService, transport));

// PRODUCT-MARKETING
export const $funnelConfigService = $transport.map(transport => createPromiseClient(FunnelConfigService, transport));
export const $productService = $transport.map(transport => createPromiseClient(ProductService, transport));
export const $upsellService = $transport.map(transport => createPromiseClient(UpsellService, transport));
export const $managerService = $transport.map(transport => createPromiseClient(ManagerService, transport));
export const $presetsService = $transport.map(transport => createPromiseClient(PresetService, transport));
export const $fallbackService = $transport.map(transport => createPromiseClient(PresetFallbackService, transport));
export const $refundPolicyRuleService = $transport.map(transport => createPromiseClient(RefundPolicyRuleService, transport));
export const $customListService = $transport.map(transport => createPromiseClient(CustomListService, transport));

// LEGAL
export const $legalService = $transport.map(transport => createPromiseClient(LegalService, transport));

//CUSTOMER MANAGEMENT
export const $customerManagementService = $transport.map(transport => createPromiseClient(CustomerManagementService, transport));
