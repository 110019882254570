import { createEvent, createStore, sample } from 'effector';
import { AlertProps, SnackbarOrigin } from '@mui/material';

interface SnackbarState {
    action: boolean;
    open: boolean;
    message: string;
    anchorOrigin: SnackbarOrigin;
    variant: string;
    alert: AlertProps;
    transition: string;
    close: boolean;
    actionButton: boolean;
}

const initialState: SnackbarState = {
    action: false,
    open: false,
    message: '',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    variant: 'default',
    alert: {
        color: 'primary',
        variant: 'filled',
        severity: 'info',
    },
    transition: 'Fade',
    close: true,
    actionButton: false,
};

const openSnackbarEv = createEvent<any>('open snackbar');
const closeSnackbarEv = createEvent('close snackbar');
const openSnackbarErrorEv = createEvent<{ message: string }>('open snackbar error');
const openSnackbarSuccessEv = createEvent<{ message: string }>('open snackbar success');

const $snackbarState = createStore<SnackbarState>(initialState);

sample({
    clock: openSnackbarEv,
    source: {
        prevSnackbarState: $snackbarState,
    },
    fn: ({ prevSnackbarState }, { open, message, anchorOrigin, variant, alert, transition, close, actionButton }) =>
        ({
            action: !prevSnackbarState.action,
            open: open || initialState.open,
            message: message || initialState.message,
            anchorOrigin: anchorOrigin || initialState.anchorOrigin,
            variant: variant || initialState.variant,
            alert: {
                color: alert?.color || initialState.alert.color,
                variant: alert?.variant || initialState.alert.variant,
            },
            transition: transition || initialState.transition,
            close: close === false ? close : initialState.close,
            actionButton: actionButton || initialState.actionButton,
        })
    ,
    target: $snackbarState,
});

sample({
    clock: closeSnackbarEv,
    source: {
        prevSnackbarState: $snackbarState,
    },
    fn: ({ prevSnackbarState }) => ({ ...prevSnackbarState, open: false }),
    target: $snackbarState,
});

sample({
    clock: openSnackbarErrorEv,
    fn: ({ message }) => ({
        open: true,
        message: message,
        variant: 'alert',
        alert: {
            color: 'error',
            severity: 'error',
        },
    }
    ),
    target: openSnackbarEv,
});

sample({
    clock: openSnackbarSuccessEv,
    fn: ({ message }) => ({
        open: true,
        message: message,
        variant: 'alert',
        alert: {
            color: 'success',
            severity: 'success',
        },
    }
    ),
    target: openSnackbarEv,
});

export const snackbarModel = {
    $snackbarState,
    openSnackbarEv,
    closeSnackbarEv,
    openSnackbarErrorEv,
    openSnackbarSuccessEv,
};
