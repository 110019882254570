import { attach, combine, createEvent, createStore, sample } from 'effector';
import { $authService } from 'shared/models/web-rpc';
import { $accessToken, $expires } from 'shared/models/user-info';
import { reset, spread } from 'patronum';
import { snackbarModel } from 'entities/snackbar';
import { jwtDecode } from 'jwt-decode';
import { AccessToken } from 'shared/types/auth';

interface LoginProps {
    email: string;
    password: string;
}

const $isLoading = createStore(true);
const $isCheckedAuth = createStore(false);
const $isAuthorized = combine($accessToken, $isCheckedAuth, (access_token, isCheckedAuth) => Boolean(access_token) && isCheckedAuth);
const $isNotAuthorized = combine($accessToken, $isCheckedAuth, (access_token, isCheckedAuth) => !access_token && isCheckedAuth);
// const $isAuthorized = $accessToken.map(token => Boolean(token));

const loginEv = createEvent<LoginProps>('login');
const logoutEv = createEvent('logout');
const checkAuthEv = createEvent('check auth');

const loginFx = attach({
    source: {
        service: $authService,
    },
    async effect({ service }, { email, password }: LoginProps) {
        const res = await service.login({
            email,
            password,
        });

        return res.accessToken;
    },
});

const logoutFx = attach({
    source: {
        service: $authService,
    },
    async effect({ service }) {
        await service.logout({});
    },
});

const checkAuthFx = attach({
    source: {
        service: $authService,
        token: $accessToken,
        expires: $expires,
    },
    async effect({ service, token, expires }) {
        const currentDate = (+new Date() / 1e3) | 0;
        const minutes = 2;
        const _expireTime = (expires - (minutes * 60));
        const expireTime = expires ? (_expireTime > 0 ? _expireTime : 0) : 0;

        if (token && expireTime > currentDate) {

            const { email, exp } = jwtDecode<AccessToken>(token);

            return {
                accessToken: token,
                email,
                exp,
            };

        } else {
            const { accessToken } = await service.refresh({});

            const email = accessToken ? jwtDecode<AccessToken>(accessToken).email : '';
            const exp = accessToken ? jwtDecode<AccessToken>(accessToken).exp : 0;

            return {
                accessToken,
                email,
                exp,
            };
        }

    },
});

const $authCheckPending = checkAuthFx.pending;

sample({
    clock: loginEv,
    target: loginFx,
});

sample({
    clock: loginFx.doneData,
    target: $accessToken,
});

sample({
    clock: loginFx.failData,
    target: snackbarModel.openSnackbarErrorEv,
});

sample({
    clock: logoutEv,
    target: logoutFx,
});

reset({
    clock: logoutFx.done,
    target: $accessToken,
});

sample({
    clock: logoutFx.failData,
    target: snackbarModel.openSnackbarErrorEv,
});

sample({
    clock: checkAuthEv,
    target: checkAuthFx,
});

sample({
    clock: checkAuthFx.doneData,
    fn: ({ accessToken }) => accessToken,
    target: $accessToken,
});

sample({
    clock: checkAuthFx.finally,
    fn: () => ({
        isCheckedAuth: true,
        isLoading: false,
    }),
    target: spread({
        targets: {
            isCheckedAuth: $isCheckedAuth,
            isLoading: $isLoading,
        },
    }),
});

const refreshTokenEv = createEvent();

sample({
    clock: refreshTokenEv,
    target: checkAuthFx,
});

export const authModel = {
    $isAuthorized,
    $isLoading,
    $isNotAuthorized,
    $authCheckPending,
    loginEv,
    logoutEv,
    checkAuthEv,
    refreshTokenEv,
};
